<template>
  <!-- Begin Page Header content -->
	<div class="page-header clearfix">
		<h2>
			<div class="pull-left">Gerenciamento de {{ currentMeta.alias }}</div>
			<div class="pull-right">
				<ul class="nav nav-pills">
					<li class="active">
            <button
              class="btn"
              @click="()=>$router.push({ name: `${currentMeta.base}.search` })">
              Pesquisar
            </button>
          </li>
					<li>
            <button
              class="btn"
              @click="()=>$router.push({ name: `${currentMeta.base}.add` })">
              Adicionar
            </button>
          </li>
				</ul>
			</div>
		</h2>
	</div>
	<!--/. End Page Header content -->
  
  <router-view />
  
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const currentMeta = router.currentRoute.value.meta;

    onMounted(function () {
      // console.log(router.currentRoute.value);
      //
    });

    return {
      currentMeta,
    };
  },
};
</script>
