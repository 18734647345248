import { createRouter, createWebHistory } from "vue-router";
import store from '@/store';

import IndexDefault from "@/views/app/index-base";

const routes = [
  {
    path: "/",
    name: "app.default",
    component: () => import("@/layouts/layout-base"),
    children: [
      {
        path: "/",
        name: "app.default.dashboard",
        component: () => import("@/views/app/dashboard"),
      },
      {
        path: "/marca",
        name: "app.marca",
        component: IndexDefault,
        meta: { alias: "Marca", base: "app.marca" },
        children: [
          {
            path: "",
            name: "app.marca.search",
            component: () => import("@/views/marca/index"),
          },
          {
            path: "add",
            name: "app.marca.add",
            component: () => import("@/views/marca/add"),
          },
          {
            path: ":id/edit",
            name: "app.marca.edit",
            component: () => import("@/views/marca/edit"),
            props: true,
          },
        ],
      },
      {
        path: "/produto",
        name: "app.produto",
        component: IndexDefault,
        meta: { alias: "Produto", base: "app.produto" },
        children: [
          {
            path: "",
            name: "app.produto.search",
            component: () => import("@/views/produto/index"),
          },
          {
            path: "add",
            name: "app.produto.add",
            component: () => import("@/views/produto/add"),
          },
          {
            path: ":id/edit",
            name: "app.produto.edit",
            component: () => import("@/views/produto/edit"),
            props: true,
          },
        ],
      },
      // {
      //   path: "/tipo",
      //   name: "app.tipo",
      //   component: IndexDefault,
      //   meta: { alias: "Tipo", base: "app.tipo" },
      //   children: [
      //     {
      //       path: "",
      //       name: "app.tipo.search",
      //       component: () => import("@/views/tipo/index"),
      //     },
      //     {
      //       path: "add",
      //       name: "app.tipo.add",
      //       component: () => import("@/views/tipo/add"),
      //     },
      //     {
      //       path: ":id/edit",
      //       name: "app.tipo.edit",
      //       component: () => import("@/views/tipo/edit"),
      //       props: true,
      //     },
      //   ],
      // },
      // {
      //   path: "/veiculo",
      //   name: "app.veiculo",
      //   component: IndexDefault,
      //   meta: { alias: "Veiculo", base: "app.veiculo" },
      //   children: [
      //     {
      //       path: "",
      //       name: "app.veiculo.search",
      //       component: () => import("@/views/veiculo/index"),
      //     },
      //     {
      //       path: "add",
      //       name: "app.veiculo.add",
      //       component: () => import("@/views/veiculo/add"),
      //     },
      //     {
      //       path: ":id/edit",
      //       name: "app.veiculo.edit",
      //       component: () => import("@/views/veiculo/edit"),
      //       props: true,
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/auth",
    name: "app.auth",
    component: () => import("@/layouts/layout-login"),
    children: [
      {
        path: "",
        name: "app.auth.login",
        component: () => import("@/views/auth/login"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@/components/NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//////////////////////////////////////
router.beforeEach((to, from, next) => {
  const publicPages = ["/auth"];
  let authRequired = !publicPages.includes(to.path);

  // store.dispatch('me').catch(() => next('/auth'));

  // const loggedIn = localStorage.getItem("token");
  const loggedIn = ((store.state.auth.userToken != "")?true:false);

  if (authRequired && !loggedIn) {
    next("/auth");
  } else {
    next();
  }
});
//////////////////////////////////////

export default router;
