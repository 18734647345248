<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {},
  setup() {},
};
</script>

<style>
  @import "assets/css/bootstrap.min.css";
  @import "assets/css/sticky-footer-navbar.css";
  @import "assets/css/theme-blue.css";

  /* .navbar-default{
    background: #ed5700;
  } */

  .card-body{
    margin-top: 10px;
  }

  .card-footer{
    text-align: right;
  }

  .container section{
    padding: 20px 10px;
    background: #f1f1f1;
    border-radius: 4px;
  }

  .cards__heading h3{
    margin: 0px;
  }

  .table-responsive{
    margin-top: 10px;
    background: #fff;
  }
</style>
