<template>
    <select
      id="inputState"
      class="form-control input-style"
      @change="emitValueChange"
      >
      <option value="" :selected="selected === ''">selecione...</option>
      <template v-for="option_ in options" :key="option_.id">
        <option
          :value="option_.id"

          :selected="selected === option_.id"
          >
          #{{ option_.id }} -> 
          {{ option_.descricao }}
          {{ option_.fantasia }}
          {{ option_.codigo }}
        </option>
      </template>
    </select>
</template>
<script>

export default {
  name: "SelectComponent",
  components: {},
  props: {
    modelValue: [String, Number],
    selected: [String, Number],
    options: {
      type: Array
    },
  },
  emits: [
    'update:modelValue'
  ],

  setup(props, { emit }) {
    const emitValueChange = (e) => {
      let value = e.target.value;
      emit('update:modelValue', value);
    }

    return {
      emitValueChange
    }
  },
};
</script>
