import { createApp } from 'vue';
import App from './App.vue';

import router from '@/router';
import store from '@/store';

//// 
import SelectComponent from '@/components/Select';
import ImageUploadComponent from '@/components/ImageUpload';
import ImageMarcaUploadComponent from '@/components/ImageMarcaUpload';

//////////////////////////
import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_URL_API;
const currentToken = store.state.auth.userToken;
if(currentToken != ''){
    axios.defaults.headers.common['Authorization'] = 'Bearer '+currentToken;
}
//////////////////////////

createApp(App)
    .use(store)
    .use(router)
    .component('y-select', SelectComponent)
    .component('image-upload', ImageUploadComponent)
    .component('image-marca-upload', ImageMarcaUploadComponent)
    .mount('#app');
