
// initial state
const state = () => ({
  //accessToken
  userToken: (window.localStorage.getItem('dataUserToken') || ''),
  user: (JSON.parse(window.localStorage.getItem('dataUser')) || null),
});

// getters
const getters = {
  getUserToken(state) {
    return state.userToken;
  },
  getUser(state) {
    return state.user;
  },
};

// actions - async
const actions = {
  setUserToken({commit}, payload) {
    localStorage.setItem('dataUserToken', payload);
    commit("_setUserToken", payload);
  },
  setUser({commit}, payload) {
    localStorage.setItem('dataUser', JSON.stringify(payload));
    commit("_setUser", payload);
  },
  userLogout({commit}){
    localStorage.setItem('dataUserToken', "");
    localStorage.setItem('dataUser', JSON.stringify({}));
    commit("_setUserToken", "");
    commit("_setUser", null);
  }
};

// mutations - sync
const mutations = {
  _setUserToken(state, userTokenNew) {
    state.userToken = userTokenNew;
  },
  _setUser(state, userNew) {
    state.user = userNew;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {},
};
