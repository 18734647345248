import { useToast } from "vue-toast-notification";
// import "vue-toast-notification/dist/theme-default.css";
import 'vue-toast-notification/dist/theme-sugar.css';

import axios from "axios";

export default function useCommon() {
  const getToast = useToast();

  const openToast = (message, type) => {
    getToast.open({
      message: message,
      type: type,
    });
  };

  const upload = (file, onUploadProgress, parseTmp) => {
    let formData = new FormData();
    formData.append("file", file);
    return axios.post("/upload" + parseTmp, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  };

  return {
    getToast,
    openToast,
    upload
  };
}
